import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeOneComponent } from './components/pages/home-one/home-one.component';
import { BlogPageComponent } from './components/pages/blog-page/blog-page.component';
import { BlogDetailsComponent } from './components/pages/blog-details/blog-details.component';
import { BannerAgentesComponent } from './components/pages/banner-agentes/banner-agentes.component';
import { ProtectedComponent } from './components/common/protected/protected.component';
import { AuthGuard } from './auth.guard';

const routes: Routes = [
    {path: '', component: HomeOneComponent},
    {path: 'blog', component: HomeOneComponent},
    {path: 'single-blog', component: BlogDetailsComponent},
    {path: 'banner-agentes', component: BannerAgentesComponent},
    {path: 'protected', component: ProtectedComponent, canActivate: [AuthGuard] }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }