<!-- Popup Overlay -->


<div class="popup-overlay" *ngIf="showPopup" (click)="disablePopup()">
    <div class="popup-content" (click)="$event.stopPropagation()">
        <img src="https://apibanner.stdorado.com/popup/popup.png" alt="Popup Image" />
      <button (click)="disablePopup()">Cerrar</button>
    </div>
  </div>  
  
  
<div  class="main-banner">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container-fluid">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-12">
                        <div class="banner-content">
                            <h1>Bienvenido a nuestro sitio de soporte </h1>
                            <p>En DoradoBet NO SE COBRA ningún tipo de valor por trabajar con nosotros.</p>
                            <div class="others-option">
                                <div class="d-flex align-items-center">
                                    <div class="option-item">
                                        <br>
                                        <a href="https://api.whatsapp.com/send?phone=51946934310%20&text=Hola%20soy" class="default-btn2"><i class="fab fa-whatsapp"></i> +51 946-934-310<span></span></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div class="banner-image">
                            <img src="assets/img/mobile.png" alt="image">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="default-shape">
        <div class="shape-1"><img src="assets/img/shape/1.png" alt="image"></div>
        <div class="shape-2 rotateme"><img src="assets/img/shape/2.png" alt="image"></div>
        <div class="shape-3"><img src="assets/img/shape/3.svg" alt="image"></div>
        <div class="shape-4"><img src="assets/img/shape/4.svg" alt="image"></div>
        <div class="shape-5"><img src="assets/img/shape/5.png" alt="image"></div>
    </div>
</div>

<section class="fun-facts-area pt-100 pb-70">
    <app-funfacts></app-funfacts>
</section>

<section id="about" class="about-area pb-100">
    <app-about></app-about>

    <div class="default-shape">
        <div class="shape-1"><img src="assets/img/shape/1.png" alt="image"></div>
        <div class="shape-2 rotateme"><img src="assets/img/shape/2.png" alt="image"></div>
        <div class="shape-3"><img src="assets/img/shape/3.svg" alt="image"></div>
        <div class="shape-4"><img src="assets/img/shape/4.svg" alt="image"></div>
        <div class="shape-5"><img src="assets/img/shape/5.png" alt="image"></div>
    </div>
</section>
<!-- 
<section id="features" class="features-area pb-70">
    <app-features></app-features>

    <div class="default-shape">
        <div class="shape-1"><img src="assets/img/shape/1.png" alt="image"></div>
        <div class="shape-2 rotateme"><img src="assets/img/shape/2.png" alt="image"></div>
        <div class="shape-3"><img src="assets/img/shape/3.svg" alt="image"></div>
        <div class="shape-4"><img src="assets/img/shape/4.svg" alt="image"></div>
        <div class="shape-5"><img src="assets/img/shape/5.png" alt="image"></div>
    </div>
</section> -->

<!-- <app-app-screenshots></app-app-screenshots> -->

<!-- <section class="testimonials-area ptb-100">
    <app-testimonials></app-testimonials>

    <div class="default-shape">
        <div class="shape-1"><img src="assets/img/shape/1.png" alt="image"></div>
        <div class="shape-2 rotateme"><img src="assets/img/shape/2.png" alt="image"></div>
        <div class="shape-3"><img src="assets/img/shape/3.svg" alt="image"></div>
        <div class="shape-4"><img src="assets/img/shape/4.svg" alt="image"></div>
        <div class="shape-5"><img src="assets/img/shape/5.png" alt="image"></div>
    </div>
</section> -->

<section class="overview-area ptb-100">
    <app-overview></app-overview>
</section>

<!-- <section class="pricing-area pt-100 pb-70">
    <app-pricing></app-pricing>

    <div class="default-shape">
        <div class="shape-1"><img src="assets/img/shape/1.png" alt="image"></div>
        <div class="shape-2 rotateme"><img src="assets/img/shape/2.png" alt="image"></div>
        <div class="shape-3"><img src="assets/img/shape/3.svg" alt="image"></div>
        <div class="shape-4"><img src="assets/img/shape/4.svg" alt="image"></div>
        <div class="shape-5"><img src="assets/img/shape/5.png" alt="image"></div>
    </div>
</section> -->
<!-- 
<section id="faq" class="faq-area ptb-100">
    <app-faq></app-faq>

    <div class="default-shape">
        <div class="shape-1"><img src="assets/img/shape/1.png" alt="image"></div>
        <div class="shape-2 rotateme"><img src="assets/img/shape/2.png" alt="image"></div>
        <div class="shape-3"><img src="assets/img/shape/3.svg" alt="image"></div>
        <div class="shape-4"><img src="assets/img/shape/4.svg" alt="image"></div>
        <div class="shape-5"><img src="assets/img/shape/5.png" alt="image"></div>
    </div>
</section> -->

<!-- <section class="team-area pt-100 pb-70">
    <app-team></app-team>
</section> -->

<!-- <app-app-download></app-app-download> -->

<!-- <section id="blog" class="blog-area pt-100 pb-70">
    <app-blog></app-blog>
</section> -->

<!-- <section id="contact" class="contact-area ptb-100">
    <app-contact></app-contact>

    <div class="default-shape">
        <div class="shape-1"><img src="assets/img/shape/1.png" alt="image"></div>
        <div class="shape-2 rotateme"><img src="assets/img/shape/2.png" alt="image"></div>
        <div class="shape-3"><img src="assets/img/shape/3.svg" alt="image"></div>
        <div class="shape-4"><img src="assets/img/shape/4.svg" alt="image"></div>
        <div class="shape-5"><img src="assets/img/shape/5.png" alt="image"></div>
    </div>
</section> -->

<!-- <app-subscribe></app-subscribe> -->