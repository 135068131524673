<div class="container">
    <div class="section-title">
        <h2>Comunicado Oficial de Seguridad</h2>
        <div class="bar"></div>
        <p>Estimados agentes y aliados, recuerden que </p><h6>en DoradoBet NO SE COBRA ningún tipo de valor por trabajar con nosotros.</h6><p> Hacerlo implicaría una cancelación inmediata de su contrato e incurrir en una demanda Penal.

            Cualquier comunicación en que supuesto personal de DoradoBet les solicite ingresar a su sistema, les pida información de sus ventas o pagos u otros, debe de ser consultada por stdorado.com (Departamento de Recarga de Saldo y Devoluciones). Con ello evitaremos que gente ajena a nuestra empresa se quiera hacer pasar como trabajador nuestro, y quiera sorprenderlos.</p>
    </div>

    <br>
    <br>
    <div class="section-title">
        <h2>Comunicado</h2>
        <div class="bar"></div>
        <p>Se le informa:
            <br>

            A partir del día Lunes 21 de Marzo del presente año:
            <br>
            <br>
            LUNES A VIERNES: Las solicitudes de pago que se recepcione de 9am a 6pm serán pagadas durante el día, y las que sean recepcionadas después serán pagadas al día siguiente.
            <br>
            SÁBADOS: Las solicitudes de pago que se recepcione de 9am a 2pm serán pagadas durante el día, y las que sean recepcionadas después serán pagadas el día Lunes.
            <br>
            DOMINGOS: Las solicitudes de pago que se recepcione en el día serán pagadas el día Lunes
            Recordar que deben de brindar datos correctos para  las SOLICITUDES DE RETIRO
            <br>
            <br>
            En caso de presentar una duda al respecto, por favor hacerla saber a su administrador.</p>
    </div>

<!--     <div class="row align-items-center">
        <div class="col-lg-6 col-md-12">
            <div class="about-content">
                <h3>Our process is simple, Our App is powerful</h3>
                <div class="bar"></div>
                <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.</p>
                <p>If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.</p>
                <div class="about-btn">
                    <a routerLink="/" class="default-btn">Download Now <span></span></a>
                </div>
            </div>
        </div>

        <div class="col-lg-6 col-md-12">
            <div class="about-image">
                <img src="assets/img/about.png" alt="image">
            </div>
        </div>
    </div> -->
</div>