<section class="footer-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-sm-8">
                <div class="single-footer-widget">
                    <a routerLink="/" class="logo"><h2>Soporte Técnico Dorado</h2></a>
                    <p>En este sitio nuestros agentes encontrarán todo lo relacionado al soporte técnico de sus locales comerciales.</p>
                    <ul class="social-list">
                        <li><a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                        <li><a href="#" target="_blank"><i class="fab fa-twitter"></i></a></li>
                        <li><a href="#" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                        <li><a href="#" target="_blank"><i class="fab fa-instagram"></i></a></li>
                    </ul>
                </div>
            </div>


            <div class="col-lg-4 col-sm-8">
                <div class="single-footer-widget">
                    <h3>Contacto</h3>

                    <ul class="list">
                        <li><a href="https://www.interplayword.com" target="_blank">Página Empresa: <br> www.interplayword.com</a></li>
                        <li><a href="https://www.doradobet.com" target="_blank">Página Principal:<br>  www.doradobet.com</a></li>
                        <li><a routerLink="/">Dirección:<br> Ricardo Palma 341 Miraflores, Lima, Perú.</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-4 col-sm-8">
                <div class="single-footer-widget">
                    <h3>Patrocinador Oficial</h3>
                    
                    <ul class="footer-holder">
                        <li><a href="#"><img src="assets/img/store/1.png" alt="image"></a></li>
                   
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="copy-right">
    <div class="container">
        <div class="copy-right-content">
            <p>
                <i class="far fa-copyright"></i> 2024 - Interplayword SAC | Todos los derechos reservados <a href="https://www.doradobet.com" target="_blank">Doradobet</a></p>
        </div>
    </div>
</div>

<div class="go-top">
    <i class="fa fa-chevron-up"></i>
    <i class="fa fa-chevron-up"></i>
</div>