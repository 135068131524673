<body style="text-align:center;background: linear-gradient(148deg, #B78821 4%, #FFB004 100%);
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;">
  <br>
    <h1>Ingrese sus credenciales</h1>
    
    <div style="background-color: white;
  border: 1px solid gray;
  border-radius: 5px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.4);
  padding: 20px;
  width: 300px;
  margin: 0 auto;
  text-align: center;" >
        <label for="email" style="display: block;margin-bottom: 10px;">Correo electrónico:</label>
        <input type="email" [(ngModel)]="email" required style="display: block; width: 95%;margin-bottom: 15px;padding: 8px;border-radius: 5px;border: 1px solid gray;"><br>
        <label for="password" style="display: block;margin-bottom: 10px;">Contraseña:</label>
        <input type="password" [(ngModel)]="password" required style="display: block; width: 95%;margin-bottom: 15px;padding: 8px;border-radius: 5px;border: 1px solid gray;"><br>
        <button (click)="login()" style="background-color: black; color: white; transition: background-color 0.5s ease; cursor: pointer;" onmouseover="this.style.backgroundColor='#3e8e41';" onmouseout="this.style.backgroundColor='#4CAF50';">Ingresar</button>
    </div>
  <br>
  
 </body>
