import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-protected',
  templateUrl: './protected.component.html',
  styleUrls: ['./protected.component.scss']
})
export class ProtectedComponent implements OnInit {
  selectedFile: File = null;
  isLoading: boolean = false; // Agregado para rastrear el estado de carga
  uploadSuccess: boolean = false; // Agregado para rastrear el éxito de la carga

  constructor(private http: HttpClient, private router: Router) { }

  ngOnInit(): void {
  }

  onFileSelected(event: Event) {
    // Restablece el estado de éxito de la carga al seleccionar un nuevo archivo
    this.uploadSuccess = false;
    const element = event.currentTarget as HTMLInputElement;
    let fileList: FileList | null = element.files;
    if (fileList) {
      this.selectedFile = fileList[0];
    }
  }

uploadFile() {
  if (!this.selectedFile) {
    alert('Por favor, selecciona una imagen para subir.');
    return;
  }

  // Comprobar que el archivo sea de tipo .png
  if (this.selectedFile.type !== 'image/png') {
    alert('Por favor, selecciona un archivo PNG.');
    return;
  }

  this.isLoading = true; // Iniciar la carga
  const formData = new FormData();
  formData.append('file', this.selectedFile, 'popup.png'); // Cambiar a un nombre fijo 'popup.png'

  const uploadEndpoint = 'https://apibanner.stdorado.com/upload.php';

  this.http.post(uploadEndpoint, formData).subscribe(
    (response) => {
      this.isLoading = false; // Finalizar la carga
      this.uploadSuccess = true; // Establecer el éxito de la carga
      alert('Imagen subida con éxito.');
      this.router.navigate(['/']); // Redirigir al inicio
    },
    (error) => {
      this.isLoading = false; // Finalizar la carga
      console.error('Error al subir la imagen:', error);
      alert('Error al subir la imagen.');
    }
  );
}
}
