import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private isAuthenticated = false;

  constructor() { }

  login(email: string, password: string): boolean {
    // Aquí implementarías la lógica de autenticación y la verificación de las credenciales.
    if (email === 'agentes@doradobet.com' && password === 'Dorado123') {
      this.isAuthenticated = true;
      return true;
    } else {
      return false;
    }
  }

  logout(): void {
    this.isAuthenticated = false;
  }

  isLoggedIn(): boolean {
    return this.isAuthenticated;
  }
}
