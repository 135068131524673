import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../../auth.service'; // Importa tu servicio de autenticación

@Component({
  selector: 'app-banner-agentes',
  templateUrl: './banner-agentes.component.html',
  styleUrls: ['./banner-agentes.component.scss']
})
export class BannerAgentesComponent implements OnInit {
  email: string = '';
  password: string = '';

  constructor(private router: Router, private authService: AuthService) {} // Inyecta el servicio de autenticación

  ngOnInit(): void {
  }

  login(): void {
    if (this.authService.login(this.email, this.password)) { // Utiliza el servicio de autenticación para verificar las credenciales
      this.router.navigate(['/protected']);
    } else {
      alert('Credenciales incorrectas');
    }
  }
}
