<div class="container">

    <div class="section-title">
        <h2>Descargas</h2>
        <div class="bar"></div>
    </div>

    <div class="row align-items-center">
        <div class="col-lg-4">

            <div class="overview-content">
                <div class="overview-image">
                    <img src="assets/img/landing/2.png" alt="image">
                </div>
                <br>
                <h4>Fondo Pantalla</h4>
                <div class="bar"></div>
                <p>Fondo de Pantalla para uso en las PC de locales.</p>
                <div class="overview-btn">
                    <a href="assets/img/landing/portada.png" download  class="default-btn">Descargar<span></span></a>

                </div>
            </div>

        </div>

        <div class="col-lg-4">

            <div class="overview-content">
                <div class="overview-image">
                    <img src="assets/img/landing/3.png" alt="image">
                </div>
                <br>
                <h4>Driver cloud</h4>
                <div class="bar"></div>
                <p> Descarga el Driver Cloud Ricoh para oficina</p>
                <div class="overview-btn">
                    <a href="assets/drivers/ricoh/login-mail-ricoh.zip" download  class="default-btn">Descargar<span></span></a>

                    
                </div>
            </div>

        </div>

        <div class="col-lg-4">

            <div class="overview-content">
                <div class="overview-image">
                    <img src="assets/img/landing/1.png" alt="image">
                </div>
                <br>
                <h4>Cambiador de IP</h4>
                <div class="bar"></div>
                <p>Descargar el cambiador de IP Epson y el Netconf</p>
                <div class="overview-btn">

         
                    <a href="assets/drivers/IP/NETCONFIG-CAMBIAR-IP.zip" download  class="default-btn">Desc. Netconfig<span></span></a>&nbsp;
             
          
                    <a href="assets/drivers/IP/EPSON-NETCONFIG.zip" download class="default-btn">Desc. Epson IP<span></span></a>
             

                </div>
                
            </div>

        </div>


    </div>
</div>

<div class="container">
    <br>
    <br>
    <br>
    <br>
    <div class="section-title">
        <h2>Drivers de Impresoras</h2>
        <div class="bar"></div>
    </div>

    <div class="row align-items-center">
        <div class="col-lg-4">

            <div class="overview-content">

                <div class="overview-btn">
                    <a href="assets/drivers/CBX-POS89E.zip" download  class="default-btn">CBX-POS89E-Printer-Driver<span></span></a>&nbsp;
                    <a href="assets/drivers/CBX-POS808-Printer-Driver.zip" download  class="default-btn">CBX-POS808-Printer-Driver<span></span></a>&nbsp;
                    <a href="assets/drivers/3nStar-RPT005.zip" download class="default-btn">3NSTAR-RPT005-Printer-Driver<span></span></a>&nbsp;
                    <a href="assets/drivers/BIXOLON_SRP350II.zip" download class="default-btn">BIXOLON_SRP350II-Printer-Driver<span></span></a>&nbsp;
                    <a href="assets/drivers/XPRINTER_POS80MM.zip" download class="default-btn">XPRINTER_POS80MM-Printer-Driver<span></span></a>&nbsp;
                </div>
            </div>

        </div>

        <div class="col-lg-4">

            <div class="overview-content">

              
                <div class="overview-btn">
                    
                    <a href="assets/drivers/im430-driver.zip" download class="default-btn">IM430-DRIVER<span></span></a>&nbsp;
                    <a href="assets/drivers/im-c300driver.zip" download class="default-btn">IM-C300-DRIVER<span></span></a>&nbsp;
                    <a href="assets/drivers/SRP-330II-332II.zip" download  class="default-btn">SRP-330II-332II<span></span></a>&nbsp;
                    <a href="assets/drivers/RP80 Series(marca-RONGTA).rar" download class="default-btn">RP80 Series(marca-RONGTA)<span></span></a>&nbsp;            
                    <a href="assets/drivers/Star_BSC10_CD_V2.1.0_Driver.zip" download class="default-btn">Star_BSC10_CD_V2.1.0_Driver<span></span></a>&nbsp;
                    <a href="assets/drivers/POS-80-LANbienex-advance-hoinprinter.zip" download class="default-btn">POS-80-LANbienex-advance-hoinprinter<span></span></a>&nbsp;
                    
                    
                </div>
            </div>

        </div>


        <div class="col-lg-4">

            <div class="overview-content">


                <div class="overview-btn">
                    <a href="assets/drivers/APD_512_T88V_EWM.zip" download  class="default-btn">EPSON-APD_512_T88V_EWM<span></span></a>&nbsp;
                    <a href="assets/drivers/APD_512_T20II_EWM.zip" download class="default-btn">EPSON-APD_512_T20II_EWM<span></span></a>&nbsp;
                    <a href="assets/drivers/APD_601_T20III_WM.zip" download class="default-btn">EPSON-APD_601_T20III_WM<span></span></a>&nbsp;
                    <a href="assets/drivers/APD_513_m30_EWM.zip" download class="default-btn">EPSON-APD_513_m30_EWM<span></span></a>&nbsp;
                </div>
            </div>

        </div>


    </div>
</div>