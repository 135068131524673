import { Component, OnInit, Renderer2, HostListener } from '@angular/core';

@Component({
  selector: 'app-home-one',
  templateUrl: './home-one.component.html',
  styleUrls: ['./home-one.component.scss']
})
export class HomeOneComponent implements OnInit {

  showPopup: boolean = true; // Controla la visibilidad del popup

  constructor(private renderer: Renderer2) { }

  ngOnInit(): void {
    // Si quieres que el popup se muestre cuando se carga la página
    this.enablePopup();
  }

  enablePopup() {
    this.showPopup = true;
    this.renderer.addClass(document.body, 'no-scroll'); // Deshabilita el scroll
  }

  disablePopup() {
    this.showPopup = false;
    this.renderer.removeClass(document.body, 'no-scroll'); // Habilita el scroll
  }

  togglePopup() {
    if (this.showPopup) {
      this.disablePopup();
    } else {
      this.enablePopup();
    }
  }

  // Listener para tecla ESC a nivel de documento
  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Escape' && this.showPopup) {
      this.disablePopup();
    }
  }
}
