<div class="container">
    <div class="row align-items-center">

        <div class="col-lg-4 col-sm-8">
            <div class="single-fun-fact">
                <div class="icon">
                    <i class="fas fa-money-bill-wave-alt"></i>
                </div>
                <h3><span>Solicitar</span></h3>
                <p>SALDO</p>
            </div>
        </div>

        <div class="col-lg-4 col-sm-8">
            <div class="single-fun-fact">
                <div class="icon">
                    <i class="fa fa-cogs"></i>
                </div>
                <h3><span>Soporte</span></h3>
                <p>TÉCNICO</p>
            </div>
        </div>

        <div class="col-lg-4 col-sm-8">
            <div class="single-fun-fact">
                <div class="icon">
                    <i class="fas fa-users-cog"></i>
                    
                </div>
                <h3><span>Soporte</span></h3>
                <p>PLATAFORMA</p>
            </div>
        </div>
    </div>
</div>