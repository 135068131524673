
<style>
        .alert {
            padding: 20px;
            background-color: #f44336;
            color: white;
        }
        .alert.success {
            background-color: #c3aa1b;
            font-size:20px; 
        }
        .closebtn {
            margin-left: 15px;
            color: white;
            font-weight: bold;
            float: right;
            font-size: 22px;
            line-height: 20px;
            cursor: pointer;
            transition: 0.3s;
        }
        .closebtn:hover {
            color: black;
        }
        .btn {
            background-color: #878E87; /* Green */
            border-radius: 20px;
            color: white;
            padding: 10px 30px;
            text-align: center;
            text-decoration: none;
            display: inline-block;
            font-size: 14px;
            margin: 4px 2px;
            cursor: pointer;
            transition: 0.3s;
        }
        .btn-ir-microsoft {
            background-color: #373737; /* Green */
            border-radius: 20px;
            color: white;
            padding: 10px 30px;
            text-align: center;
            text-decoration: none;
            display: inline-block;
            font-size: 16px;
            margin: 4px 2px;
            cursor: pointer;
            transition: 0.3s;
        }
        .btn:hover {
            background-color: rgb(33, 33, 32);
        }
        .btn-ir-microsoft:hover {
            background-color: rgb(60, 38, 201);
        }
    </style> 

<body style="text-align: center">
    <br>
    <h1>Cargar Banner</h1>
    <p>Medidas a usar tamaño "1080px x 900px" o "800px x 900px". Tipo de Formato .PNG</p>

    <div class="alert success">
    <!--     <input type="file" (change)="onFileSelected($event)" hidden #fileInput> -->
        <input type="file" (change)="onFileSelected($event)" accept="image/png" hidden #fileInput>
        <button class="btn" (click)="fileInput.click()" [disabled]="isLoading">Seleccionar Imagen</button>
        <button class="btn-ir-microsoft" (click)="uploadFile()" [disabled]="isLoading">Subir Imagen</button>
        <div *ngIf="isLoading">Cargando...</div>
        <!-- Agregado mensaje de éxito -->
        <div *ngIf="uploadSuccess" class="alert alert-success">
            Imagen subida con éxito.
        </div>
    </div>
    <a href="/banner-agentes">Cerrar sesión</a>
    <!-- Agregar esta línea si quieres mostrar información sobre la imagen seleccionada -->
    <div *ngIf="selectedFile">
        <p>Nombre del archivo: {{ selectedFile.name }}</p>
        <p>Tamaño del archivo: {{ selectedFile.size }} bytes</p>
    </div>
</body>


